/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import { Link } from "react-router-dom";
import { HashLink as Link } from 'react-router-hash-link';

// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Row,
  Col,
  NavLink,
} from "reactstrap";

import { ReactComponent as Logo } from '../assets/img/roam-wordmark-white.svg';

class ColorNavbar extends React.Component {

  constructor(props) {
  super(props);
    this.state = {
      width: 0,
      height: 0,
      navbarColor: "navbar-transparent",
      logoHeight: 50,
      collapsed: true
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleNavbar = this.toggleNavbar.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("scroll", this.changeNavbarColor);
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeNavbarColor);
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
    console.log(this.state.height);
  }
  toggleNavbar() {
    this.setState({
      collapsed: !this.state.collapsed
    });
  }


  changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 125 ||
      document.body.scrollTop > 125
    ) {
      this.setState({
        navbarColor: "bg-primary",
        logoHeight: 30
      });
    } else if (
      document.documentElement.scrollTop < 126 ||
      document.body.scrollTop < 126
    ) {
      this.setState({
        navbarColor: "navbar-transparent",
        logoHeight: 50
      });
    }
  };
  render() {
    return (
      <>
        <Navbar className={"fixed-top " + this.state.navbarColor} expand="lg">
          <Container>
            <div className="navbar-translate">
              <NavbarBrand to="/#home" tag={Link} id="logo" className="navbar-logo">
                <Logo style={{"height":this.state.logoHeight}}/>
              </NavbarBrand>
              <button className="navbar-toggler" id="navigation">
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <UncontrolledCollapse navbar toggler="#navigation">
                <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <Logo style={{"height":this.state.logoHeight}}/>
                  </Col>
                  <Col className="collapse-close text-right" xs="6">
                    <button className="navbar-toggler" id="navigation">
                      <i className="tim-icons icon-simple-remove" />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-auto" navbar>
                <NavItem className="active">
                  <NavLink tag={Link} to="/#platform">
                    Platform
                  </NavLink>
                </NavItem>
                <NavItem className="active">
                  <NavLink tag={Link} to="/#examples">
                    Examples
                  </NavLink>
                </NavItem>
                <NavItem className="active">
                  <NavLink tag={Link} to="/#ourApp">
                    App
                  </NavLink>
                </NavItem>
                <NavItem className="active">
                  <NavLink tag={Link} to="/#ourTeam">
                    Team
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink style={{"marginTop":-7}} tag={Link} to="/login-page">
                    <Button
                      style={{"paddingLeft":25, "paddingRight":25 }}
                      className="nav-link btn-round "
                      color="primary"
                      size="sm"
                    >
                      <p>  Sign In  </p>
                    </Button>
                  </NavLink>
                </NavItem>
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default ColorNavbar;
