/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch} from "react-router-dom";

// styles
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.0.0";
// import "assets/demo/demo.css";
// import "assets/demo/react-demo.css";

// presentation pages
import ContactUs from "views/ContactUs.jsx";
import LandingPage from "views/LandingPage.jsx";
import Error404 from "views/Error404.jsx";
import Error500 from "views/Error500.jsx";
import LoginPage from "views/LoginPage.jsx";


ReactDOM.render(
  <HashRouter basename='/'>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/contact-us" render={props => <ContactUs {...props} />} />
      <Route path="/404-error" render={props => <Error404 {...props} />} />
      <Route path="/500-error" render={props => <Error500 {...props} />} />
      <Route path="/login-page" render={props => <LoginPage {...props} />} />
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
