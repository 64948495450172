/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// react plugin used to create charts
import { HashLink as Link } from 'react-router-hash-link';

// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Badge,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

// core components
import ColorNavbar from "components/ColorNavbar.jsx";
import SimpleFooter from "components/SimpleFooter.jsx";

class LandingPage extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("landing-page");
  }
  render() {
    return (
      <>
        <ColorNavbar />
        <div id="home" className="wrapper" ref="wrapper">
        <div className="header header-4">
          <div className="header-wrapper">
            <div className="page-header header-video header-filter">
              <div className="overlay" />
              <video
                autoPlay="autoplay"
                loop="loop"
                muted="muted"
                playsInline="playsinline"
              >
                <source
                  src={require("assets/video/globe-3.mov")}
                  type="video/mp4"
                />
              </video>
              <Container className="text-left">
                <div className="video-text">
                  <h1 className="title">A new world to Roam</h1>
                  <h2 className="description">Bringing the best travel and historic sites to life with world-scale immersive experiences</h2>
                  <br />
                  <Link to="/#platform">
                    <Button
                      className="btn-round"
                      color="primary"
                    >
                      Learn More
                    </Button>
                  </Link>
                </div>
              </Container>
            </div>
          </div>
        </div>
          <div id="platform" className="roam-features features-1">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h1 className="title">Future of development and delivery of location-aware, immersive experiences </h1>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col lg="12">
                  <Row className="row-grid justify-content-center">
                    <Col lg="3">
                      <div className="info">
                        <div className="roam-feature">
                          <img className= "roam-icon" alt="..." src={require("assets/img/roam-studio-2.png")}/>
                        </div>
                        <h4 className="info-title">Content Studio</h4>
                        <hr className="line-warning" />
                        <p>
                          Work with our AR-optimized content production team to build new immersive experiences
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-warning">
                          <img className= "roam-icon" alt="..." src={require("assets/img/roam-platform-2.png")}/>
                        </div>
                        <h4 className="info-title">Location XR Platform</h4>
                        <hr className="line-warning" />
                        <p>
                          Manage locations, attractions, and immersive content from one location
                        </p>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon icon-success">
                          <img className= "roam-icon" alt="..." src={require("assets/img/roam-sdk-2.png")}/>
                        </div>
                        <h4 className="info-title">Development SDK</h4>
                        <hr className="line-warning" />
                        <p>
                          Add immersive, location-aware experiences quickly to existing apps via plug and play SDK
                        </p>
                        <br />
                        <Badge color="warning">Coming Soon!</Badge>
                      </div>
                    </Col>
                    <Col lg="3">
                      <div className="info">
                        <div className="icon">
                          <img className= "roam-icon" alt="..." src={require("assets/img/roam-smartphone-2.png")}/>
                        </div>
                        <h4 className="info-title">App Services</h4>
                        <hr className="line-warning" />
                        <p>
                          Lean on our team to help you build your entire XR experience for multiple form factors
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Container>
          </div>
          {/* ********* BLOGS 2 ********* */}
          <div className="blogs-2" id="examples">
            <Container fluid>
              <h2 className="title">World-Scale AR Examples</h2>
              <br />
              <Row>
                <Col lg="3">
                  <Card
                    className="card-blog card-background"
                    data-animation="zooming"
                  >
                    <div
                      className="full-background"
                      style={{
                      }}>
                      <video
                        autoPlay
                        loop="loop"
                        muted="muted"
                        playsInline="playsinline"
                        style={{width:'100%'}}

                      >
                        <source
                          src={require("assets/video/airship.mp4")}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <CardBody>
                      <div className="content-bottom">
                        <h6 className="card-category">World-Scale AR</h6>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <CardTitle tag="h3">Point Cloud AR Positioning</CardTitle>
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-blog card-background"
                    data-animation="zooming"
                  >
                  <div
                    className="full-background"
                    style={{
                    }}>
                    <video
                      autoPlay="autoplay"
                      loop="loop"
                      muted="muted"
                      playsInline="playsinline"
                      style={{width:'100%'}}
                    >
                      <source
                        src={require("assets/video/drwho.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                    <CardBody>
                      <div className="content-bottom">
                        <h6 className="card-category">World-Scale AR</h6>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <CardTitle tag="h3">
                            Geo Located Experiences
                          </CardTitle>
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-blog card-background"
                    data-animation="zooming"
                  >
                  <div
                    className="full-background"
                    style={{
                    }}>
                    <video
                      autoPlay="autoplay"
                      loop="loop"
                      muted="muted"
                      playsInline="playsinline"
                      style={{width:'100%'}}
                    >
                      <source
                        src={require("assets/video/windmill.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                    <CardBody>
                      <div className="content-bottom">
                        <h6 className="card-category">World-Scale AR</h6>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <CardTitle tag="h3">Immersive Interaction</CardTitle>
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3">
                  <Card
                    className="card-blog card-background"
                    data-animation="zooming"
                  >
                  <div
                    className="full-background"
                    style={{
                    }}>
                    <video
                      autoPlay="autoplay"
                      loop="loop"
                      muted="muted"
                      playsInline="playsinline"
                      style={{width:'100%'}}
                    >
                      <source
                        src={require("assets/video/skeleton.mp4")}
                        type="video/mp4"
                      />
                    </video>
                  </div>
                    <CardBody>
                      <div className="content-bottom">
                        <h6 className="card-category">World-Scale AR</h6>
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          <CardTitle tag="h3">Environment Awareness</CardTitle>
                        </a>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center mt-4" md="4" style={{"marginTop":60,"marginBottom":60}}>
                  <h3 className="title">Let's make something fun together.</h3>
                  <h4 className="description mb-8" style={{"marginTop":-60}}>
                    Drop us a line and we can start to figure out how to bring some theme-park magic to your location.
                  </h4>
                  <Link to="/contact-us">
                    <Button color="success" size="lg" className="btn-round">
                      Contact us
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END BLOGS 2 ********* */}
          {/* ********* HEADER 5 ********* */}
          <div id="ourApp" className="header-5">
            <div className="">
              <Container>
                <Row className="align-items-center mt-md-5">

                  <Col className="ml-auto text-center" lg="4">
                    <div className="phone-container">
                      <img
                        alt="..."
                        className="phone"
                        src={require("assets/img/roam-phone-screen.png")}
                      />
                    </div>
                  </Col>
                  <Col className="ml-auto mr-auto" lg="6" md="8">
                    <h2 style={{color:'#32325d'}} className="title text-left">
                      A little something extra we have been cooking up.
                    </h2>
                    <h4 className="description">With the ROAM augmented travel companion you can find and enjoy the best immersive content and experiences wherever your travels take you.</h4>
                    <InputGroup
                      className={classnames({
                        "input-group-focus": false
                      })}
                    >
                      <Input
                        placeholder="Enter your email"
                        type="text"
                        onFocus={e => this.setState({ focus: true })}
                        onBlur={e => this.setState({ focus: false })}
                      />
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <i className="tim-icons icon-lock-circle" />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <Button
                      className="btn-round"
                      color="primary"
                      href=""
                    >
                      Keep me posted
                    </Button>
                  </Col>
                </Row>

              </Container>
            </div>
          </div>
          {/* ********* END HEADER 5 ********* */}
          {/* ********* TEAM 2 ********* */}
          <div className="team-2" id="ourTeam">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Our Team</h2>
                  <h4 className="description">
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <Card className="card-profile">
                    <div className="card-image">
                      <h4 className="title">Steve Wirig</h4>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/stevewirig.png")}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h5 className="job-title">Product Dev & Strategy</h5>
                      <h5 className="job-title">Product Leader at Adobe driving Experience Management solutions</h5>


                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile">
                    <div className="card-image">
                      <h4 className="title">Carter Hogan</h4>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/carterhogan.png")}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h5 className="job-title">Content Production</h5>
                      <h5 className="job-title">Content Manager and Producer with TeamSupertramp</h5>

                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile">
                    <div className="card-image">
                      <h4 className="title">Lauri Rustanius</h4>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/lauri.png")}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h5 className="job-title">AR & VR Master</h5>
                      <h5 className="job-title">AR developer previously working with Mapbox Location-based AR Platform</h5>

                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile">
                    <div className="card-image">
                      <h4 className="title">Kevin Keele</h4>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/kevin.jpeg")}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h5 className="job-title">3D Content Lead</h5>
                      <h5 className="job-title">3D Artist with Disney Interactive, castAR, and WarnerBros Games</h5>

                    </CardBody>
                  </Card>
                </Col>
                <Col md="4">
                  <Card className="card-profile">
                    <div className="card-image">
                      <h4 className="title">Kimball Frank Wirig</h4>
                      <a href="#pablo" onClick={e => e.preventDefault()}>
                        <img
                          alt="..."
                          className="img img-raised rounded"
                          src={require("assets/img/kimballfrank.jpg")}
                        />
                      </a>
                    </div>
                    <CardBody>
                      <hr className="line-primary" />
                      <h5 className="job-title">UX & Design</h5>
                      <h5 className="job-title">Experience designer and developer with Munkee Apps and Reform Collective</h5>

                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center mt-4" md="8">

                <Row style={{"marginTop":50}}>
                  <Col className="ml-auto mr-auto text-center mt-4" md="8">
                    <h3 className="title">Wanna join us?</h3>
                    <h4 className="description mb-5">
                      We're always looking for talented 3D Artists, Animators and Unity & React Developers
                    </h4>
                    <Link to="/contact-us">
                      <Button color="success" size="lg" className="btn-round">
                        Contact us
                      </Button>
                    </Link>
                  </Col>
                </Row>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END TEAM 2 ********* */}
          <SimpleFooter />
        </div>
      </>
    );
  }
}

export default LandingPage;
