/*!

=========================================================
* BLK Design System PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// import { Link } from "react-router-dom";

// reactstrap components
import {
  NavLink,
  Container,
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
      <footer className="footer footer-simple">
        <Container>
          <nav>
            <ul>
              <li>
                <NavLink
                  href="/login-page"
                >
                  Sign In
                </NavLink>
              </li>
              <li>
                <NavLink
                  href="/home#platform"
                  className="ml-1"
                >
                  Platform
                </NavLink>
              </li>
              <li>
                <NavLink
                  href="/home#ourTeam"
                  className="ml-1"
                >
                  Team
                </NavLink>
              </li>
              <li>
                <NavLink
                  href="/contact-us"
                  className="ml-1"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="copyright">
            © {new Date().getFullYear()} Roam Labs
          </div>
        </Container>
      </footer>
      </>
    );
  }
}

export default SimpleFooter;
